@import "../../../Stylesheets/variables.scss";

// .image-left,
// .image-right {
//     width: 50%;
//     height: auto;
// }

.container1 {
    background-color: #6080A0;
    width: 100%;
    height: 100%;
    padding-top: 80px;
}

.container {
    background-color: #6080A0;
    width: 100%;
    height: 100%;
}

.big-image {
    height: 100%;
    width: 100%;
}

.section-title {
    margin: 10%;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.Section2 {
    background-color: $blue_Color4;
    /* padding: 250px;
    padding-top: 50px;
    padding-bottom: 50px; */
}

.Section4 {
    background-color: $blue_Color3;
}

.Section1 {
    position: relative;
    height: 70vh;
    background-image: url('../../../imagenes/Portada_4.jpg');
    /* Cambia esto por la ruta de tu imagen */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo semitransparente */
    color: white;
    /* Color del texto */
    text-align: center;
    /* padding: 20px; */
}

h1,
p {
    margin: 3%;
    padding: 0;
}