.text-center .container {
    background-color: #6080A0;
    width: 100%;
    height: 100%;
    /* padding-top: 40px; */
}

.portfolio-image {
    position: relative; /* Relative positioning for overlay and text */
    display: flex; /* Flexbox to center content */
    justify-content: top; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; /* Each slide is as wide as the container */
    height: 800px; /* Set the height of the carousel */
    flex-shrink: 0; /* Prevents the slide from shrinking */
    transition: transform 0.5s ease; /* Smooth transition for sliding effect */
    overflow: hidden; /* Ensures no overflow from images */
}

.portfolio-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 40%;
}