.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.row {
    margin-right: 15px;
    margin-left: 15px;
}

.portfolio-item {
    margin: 1px -15px 0 -14px;
    padding: 0;
}

.portfolio-item .hover-bg {
    display: block;
    position: relative;
    margin: 0;
}

.hover-bg .hover-text {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    background: rgba($blue_Color1, 0.8);
    padding: 30% 0 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
}

.hover-bg .hover-text>h4 {
    opacity: 0;
    color: #fff;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all 0.3s;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
}

.hover-bg:hover .hover-text>h4 {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.hover-bg:hover .hover-text {
    opacity: 1;
}

.grid-container {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.grid-item {
    background-color: #f1f1f1;
    text-align: center;
    font-size: 30px;
    
}
