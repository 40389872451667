.galeria {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Tamaño mínimo de las columnas */
    gap: 20px; /* Espacio entre las imágenes */
}

.galeria-item {
    display: flex;
    justify-content: center;
    // align-items: center;
}

.galeria img {
    max-width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s; /* Transición suave */
}

.galeria img:hover {
    transform: scale(1.05); /* Aumenta el tamaño de la imagen al pasar el mouse por encima */
}

/* Asegúrate de que el último elemento de la cuadrícula sea centrado */
.galeria::after {
    content: '';
    flex: auto;
}
