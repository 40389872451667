.NotFound {
    margin: 0 auto;
    text-align: center; /* Center align content */
    font-family: Arial, sans-serif; /* Choose your preferred font */
    padding-bottom: 48px;
    width: 100%;
    height: 100%;
    padding-top: 200px;
    background-color: #1C2D49;
    color: white;
    padding-bottom: 134px;
}

.NotFound h1 {
    font-size: 36px; /* Adjust font size as needed */
    margin-bottom: 20px; /* Space below the heading */
}

.NotFound p {
    font-size: 18px; /* Adjust font size as needed */
    line-height: 1.6; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below paragraphs */
}

.NotFound a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    text-decoration: none;
    border-radius: 4px; /* Button border radius */
    transition: background-color 0.3s ease;
}

.NotFound a:hover {
    background-color: #0056b3; /* Hover state background color */
}
