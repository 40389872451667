/* Base styles */
.page-container {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.Inicio1 {
    background-color: #6080A0;
}

.Inicio1 .text-right {
    color: white; 
    text-align: justify; 
    font-size: 20px;
    line-height: 2.4;
    padding: 60px 20px 100px;
}

.Inicio1 .image-left {
    width: 50%; 
    height: 100vh;
    object-fit: cover;
    object-position: center bottom;
}

.Inicio1 h2 {
    font-weight: bold;
    text-align: left; /* Ensure the title is left-aligned */
    margin: 0;
    margin-bottom: 20px;
}

.video-text-left h2 {
    text-align: center;
    font-size: 34px; 
}

.Inicio1 .paragraph {
    text-align: justify; 
    line-height: 2.5; 
}

.Inicio2 {
    background-color: #2F4A73;
}

.Inicio2 .text-left {
    color: white; 
    text-align: justify; 
    font-size: 20px;
    line-height: 2.4;
    padding: 60px 20px 100px;
}

.Inicio2 .image-right {
    width: 50%; 
    height: 100vh; 
    object-fit: cover; 
    object-position: center bottom; 
}

.Inicio2 h2 {
    font-weight: bold;
    text-align: left; /* Ensure the title is left-aligned */
    margin: 0;
    margin-bottom: 20px;
}

.text-left .paragraph {
    text-align: justify; 
    line-height: 2.0; 
}

.text-right .paragraph {
    text-align: justify; 
    line-height: 2.0; 
}

.video-section {
    background-color: #2F4A73;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.slider-image {
    width: 60%;
    height: auto;
    background-color: white;
}

.home-slider {
    background-color: white;
}

/* Mobile screens */
@media (max-width: 601px) {
    section {
        flex-direction: column;
    }

    .text-left, .text-right, .image-left, .image-right {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .text-left, .text-right {
        order: 1;
        padding: 20px;
    }
    
    .Inicio1  .text-right {
        order: 1;
        padding: 40px;
        font-size: 15px;
    }
    
    .Inicio2 .text-left {
        order: 1;
        padding: 40px;
        font-size: 15px;
    }

    .image-left, .image-right {
        order: 2;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .Inicio1 .image-left {
        width: 100%;
        height: 75vh;
    }
    
    .Inicio2 .image-right {
        width: 100%;
        height: 75vh;
    }

    .video-section iframe {
        width: 100%;
        aspect-ratio: 16 / 9; /* Mantiene la relación de aspecto del video */
        margin: 0; /* Elimina cualquier margen */
        padding: 0; /* Elimina cualquier padding */
        box-sizing: border-box; /* Asegura que el tamaño se incluya en el ancho total */
    }

    .values-list li {
        font-size: 14px;
        line-height: 1.8;
    }

    .mission h2, .vision h2 {
        text-align: left; /* Ensure the title is left-aligned */
    }

    /* Ensure carousel container height is auto on mobile screens */
    .carousel-container {
        height: auto;
    }
}

/* Tablet screens */
@media (min-width: 601px) and (max-width: 1280px) {
    section {
        flex-direction: column;
    }

    .text-left, .text-right, .image-left, .image-right {
        width: 100%;
        text-align: left;
        margin: 0;
    }

    .text-left, .text-right {
        order: 1;
        padding: 40px;
    }

    .Inicio1  .text-right {
        order: 1;
        padding: 40px;
    }

    .Inicio2 .text-left {
        order: 1;
        padding: 40px;
    }

    .image-left, .image-right {
        order: 2;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    
    .Inicio1 .image-left {
        width: 100%;
        height: 75vh;
    }
    
    .Inicio2 .image-right {
        width: 100%;
        height: 75vh;
    }

    .video-section iframe {
        width: 100%;
        aspect-ratio: 16 / 9; /* Mantiene la relación de aspecto del video */
        margin: 0; /* Elimina cualquier margen */
        padding: 0; /* Elimina cualquier padding */
        box-sizing: border-box; /* Asegura que el tamaño se incluya en el ancho total */
    }

    .values-list li {
        font-size: 16px;
        line-height: 2.0;
    }

    .mission h2, .vision h2 {
        text-align: left; /* Ensure the title is left-aligned */
    }
}