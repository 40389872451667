/* Carousel Container */
.carousel-container {
    width: 100%;
    height: 800px;
    position: relative;
    overflow: hidden;
    background-color: #6080A0;
}

/* Slides */
.carousel-slide {
    position: relative; /* Relative positioning for overlay and text */
    display: flex; /* Flexbox to center content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; /* Each slide is as wide as the container */
    height: 800px; /* Set the height of the carousel */
    flex-shrink: 0; /* Prevents the slide from shrinking */
    transition: transform 0.5s ease; /* Smooth transition for sliding effect */
    overflow: hidden; /* Ensures no overflow from images */
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute; /* Absolute positioning to cover the slide */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.carousel-text {
    position: absolute; /* Absolute positioning to place text over image */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center text */
    color: white; /* Text color */
    text-align: center; /* Centered text */
    z-index: 1; /* Ensure text is above the overlay */
}

.carousel-text h1 {
    /* font-size: 2em; */
    margin-bottom: 10px;
    /* font-family: "Poppins", sans-serif; */
    font-style: italic;
}

.carousel-text p {
    font-size: 1.2em;
}

/* Navigation Arrows */
.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    background-color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.carousel-arrow.left {
    left: 10px;
}

.carousel-arrow.right {
    right: 10px;
}

/* Dots for Slide Indicators */
.carousel-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    z-index: 100;
}

.carousel-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
}

.carousel-dot.active {
    background-color: #333;
}

@media (max-width: 601px) {
    .carousel-slide {
        height: 300px;
    }

    .carousel-text h1 {
        font-size: 1.5em;
    }

    .carousel-text p {
        font-size: 1em;
    }
}
