@import "../../Stylesheets/variables.scss";

.container_footer {
    background-color: $blue_Color1;
    color: white;
    padding: 50px 0;
    width: 100%;
}

.footer-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
}

.footer-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.footer-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.footer-menu-item {
    margin: 10px 0;
}

.footer-menu-item a {
    color: $white_color;
    text-decoration: none;
}

.footer-menu-item a:hover {
    text-decoration: underline;
}

.footer-social-media {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.footer-social-media-item {
    margin-right: 20px;
    font-size: 30px;
    transition: all 0.3s ease-in-out;
}

.footer-social-media-item a {
    color: $white_color;
}

.footer-social-media-item:hover {
    transform: scale(1.2);
}

.footer-text {
    font-size: 14px;
    margin-top: 30px;
    text-align: center;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
    .footer-columns {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .footer-column {
        padding: 20px 0;
    }

    .footer-social-media {
        flex-wrap: wrap;
    }

    .footer-social-media-item {
        margin-right: 10px;
        margin-left: 10px;
    }
}
