.Privacy_Document {
    text-align: center; /* Center align content */
    color: white;
    background-color: #1C2D49;
    height: 100%;
    width: 100%;
    padding-top: 180px;
    padding-bottom: 50px;

}

.Privacy_Document h2 {
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 20px; /* Space below the heading */
    border-bottom: 1px solid #ccc; /* Bottom border for separation */
    padding-bottom: 10px; /* Space below the border */
}

.Privacy_Document p {
    margin: 20px 0; /* Space above and below paragraphs */
    line-height: 1.6; /* Adjust line height for readability */
    margin-left: 100px;
    margin-right: 100px;
}

.Privacy_Document p:last-child {
    margin-bottom: 0; /* Remove bottom margin for the last paragraph */
}

.Privacy_Document p:first-child {
    margin-top: 0; /* Remove top margin for the first paragraph */
}

.Privacy_Document .last-modified {
    font-weight: bold;
    text-align: right;
    margin-top: 20px; /* Top margin for the last modified date */
}

@media (max-width: 768px){
    .Privacy_Document {
        padding-top: 50px;    
    }

    .Privacy_Document p {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (min-width: 601px) and (max-width: 1280px){
    .Privacy_Document {
        padding-top: 50px;
    }
}