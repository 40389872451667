small,
.small {
    font-size: 85%;
}

mark,
.mark {
    padding: .2em;
    background-color: #fcf8e3;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-muted {
    color: #777;
}

.text-primary {
    color: #337ab7;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
    display: table;
    content: " ";
}

.container:after,
.container-fluid:after{
    clear: both;
}

.w-36 {
    padding-top: 30px;
    padding-bottom: 30px;
}